<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article>
      <asom-card>
        <form class="grid sm:grid-cols-1 md:grid-cols-4 gap-2">
          <h3
            class="col-span-4 border-b border-gray-700 text-md font-semibold pb-2 pt-6"
          >
            Circular Procedure Update Content
          </h3>
          <asom-form-field
            class="col-span-2"
            :label="'Title'"
            :state="inputStates('formData.title')"
            error="Title is required"
            required
          >
            <asom-input-text
              v-model="formData.title"
              :state="inputStates('formData.title')"
            />
          </asom-form-field>
          <asom-form-field
            class="col-span-4"
            required
            label="Description"
            :state="inputStates('formData.description')"
            error="Description is required"
          >
            <asom-input-textarea
              type="text"
              v-model="formData.description"
              :state="inputStates('formData.description')"
              :maxlength="1000"
            />
          </asom-form-field>
          <asom-form-field
            required
            class="col-span-4"
            label="Supporting documents"
            :state="inputStates('formData.attachments')"
            error="Please upload supporting documents"
          >
            <asom-upload
              v-model="formData.attachments"
              :category="attachmentCategories.OPS_COMM"
              :state="inputStates('formData.attachments')"
            />
          </asom-form-field>

          <h3
            class="col-span-4 border-b border-gray-700 text-md font-semibold pb-2 pt-6"
          >
            Notification Settings
          </h3>
          <asom-form-field
            class="col-span-2"
            label="Expiry Date"
            :state="inputStates('formData.expiryDate')"
            error="Expiry Date is required"
          >
            <asom-input-date
              :min-date="new Date()"
              v-model="formData.expiryDate"
              :state="inputStates('formData.expiryDate')"
            />
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            label="Acknowledgement Deadline Date"
            required
            :state="inputStates('formData.acknowledgementDeadlineDate')"
            error="Acknowledgement Deadline Date is required"
          >
            <asom-input-date
              :min-date="new Date()"
              v-model="formData.acknowledgementDeadlineDate"
              :state="inputStates('formData.acknowledgementDeadlineDate')"
            />
          </asom-form-field>
          <h3
            class="col-span-4 border-b border-gray-700 text-md font-semibold pb-2 pt-6"
          >
            Recipient Settings
          </h3>
          <div class="col-span-4">
            <asom-input-checkbox-group
              horizontal
              v-model="formData.lineIds"
              :options="computedLineOptions"
            />
          </div>
          <asom-form-field
            class="col-span-2"
            :required="isStationsAndRolesRequired"
            label="Stations"
            :state="inputStates('formData.zoneStations')"
            error="Stations required"
          >
            <asom-multi-select
              :objectModelValue="false"
              placeholder="Select Stations"
              :state="inputStates('formData.zoneStations')"
              v-model="formData.zoneStations"
              :options="zoneStationsOptions"
              :object-model-value="true"
            />
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            :required="isStationsAndRolesRequired"
            label="Roles"
            :state="inputStates('formData.roles')"
            error="Roles required"
          >
            <asom-multi-select
              placeholder="Select Roles"
              :state="inputStates('formData.roles')"
              v-model="formData.roles"
              :loading="isLoadingRoleOptions"
              :options="roleOptions"
            />
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            label="Individual Recipients"
            :state="inputStates('formData.individualRecipients')"
            error="Individual Recipients required"
          >
            <asom-multi-select
              placeholder="Select Individual Recipients"
              :state="inputStates('formData.individualRecipients')"
              v-model="formData.individualRecipients"
              @search-change="findUsers"
              :options="officerOptions"
            />
          </asom-form-field>

          <div class="col-span-4 flex justify-end space-x-4 pt-8">
            <asom-button
              text="Back"
              variant="secondary"
              @click="$router.go(-1)"
            />
            <asom-button
              text="Create"
              @click="createCPUpdateClicked"
              :loading="isSubmitting"
            />
          </div>
        </form>
      </asom-card>
    </article>
  </div>
</template>

<script>
import isEmpty from "lodash.isempty";
import get from "lodash.get";
// import flatten from "lodash.flatten";
import filter from "lodash.filter";
import { mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { createCPUpdate } from "../../../services/opsComms.service";
import { listUsers } from "@/services/user.service";
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";
import ROLES from "../../../constants/APIEnums/roles";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  data() {
    return {
      error: null,
      isSubmitting: false,
      officerOptions: [],
      isLoadingRoleOptions: false,
      isLoadingOfficerOptions: false,
      formData: {
        title: null,
        expiryDate: null,
        acknowledgementDeadlineDate: null,
        lineIds: [],
        zoneStations: [],
        roles: null,
        individualRecipients: [],
        description: null,
        attachments: [],
      },
    };
  },
  validations() {
    return {
      formData: {
        title: { required },
        acknowledgementDeadlineDate: { required },
        zoneStations: this.isStationsAndRolesRequired ? { required } : {},
        roles: this.isStationsAndRolesRequired ? { required } : {},
        description: { required },
        attachments: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      userStationId: "auth/userStationId",
      lineOptions: "smrtResource/lineOptions",
      userLineId: "auth/userLineId",
      roleOptions: "adminResource/cpUpdateRoleOptions",
      canCreateOpsCommCircularsForBothLines: "auth/canCreateOpsCommCircularsForBothLines",
    }),
    zoneStationsOptions() {
      if (!this.canCreateOpsCommCircularsForBothLines)
        return this.$store.getters["smrtResource/zoneStationOptionsByLineId"](this.userLineId);
      return this.$store.getters["smrtResource/allZoneStationOptionsByLineId"];
    },
    computedLineOptions() {
      return this.canCreateOpsCommCircularsForBothLines ?
        this.lineOptions :
        filter(this.lineOptions, {
          value: this.userLineId,
        });
    },
    isStationsAndRolesRequired(){
      return (this.formData.individualRecipients == null || this.formData.individualRecipients.length === 0);
    },
    attachmentCategories() {
      return ATTACHMENT_CATEGORIES;
    }
  },
  mounted(){
    this.setRoleOptions();
  },
  watch: {
    'formData.lineIds': function(newValues) {
      this.formData.zoneStations = this.zoneStationsOptions
        .filter(({ lineId }) => newValues.includes(lineId));
    },
  },
  methods: {
    setRoleOptions() {
      if (isEmpty(this.allRoleOptions))
        this.$store.dispatch('adminResource/getRoles');
    },
    async findUsers(query) {
      this.error = null;
      this.isLoadingOfficerOptions = true;
      if (!query) {
        this.isLoadingOfficerOptions = false;
        this.officerOptions = [];
      }
      const result = await listUsers({
        skip: 0,
        take: 100,
        search: query.trim(),
        stationId: this.formData.station ? this.formData.station.value : null,
      });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.isLoadingOfficerOptions = false;
        this.error = null;
        const validRoles = [
            ROLES.STATION_MANAGER.name,
            ROLES.ASSISTANT_STATION_MANAGER.name,
            ROLES.SENIOR_STATION_MANAGER.name,
            ROLES.SENIOR_ASSISTANT_STATION_MANAGER.name,
            ROLES.STATION_MANAGER_CONTROL_ROOM.name,
          ];
        this.officerOptions = get(result, "payload.list", [])
        .filter(({roles}) => { return roles.filter(role => validRoles.includes(role)).length })
        .map(
          ({ id, name, personNo }) => ({
            label: `${name} (${personNo})`,
            value: id,
          })
        );
      } else {
        this.isLoadingOfficerOptions = false;
        this.error = result.payload;
        this.officerOptions = [];
      }
    },
    createCPUpdateClicked() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.onSubmit();
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      this.isSubmitting = true;
      let uploadedIds = [];
      let lineStations = [];
      let roleIds = [];
      let individualRecipientsIds = [];
      if (this.formData.attachments) {
        uploadedIds = this.formData.attachments.map((file) => file.fileId);
      }
      if (this.formData.zoneStations) {
        lineStations = this.formData.zoneStations.map(({ lineId, stationId }) => ({
          lineId,
          stationId
        }));
      }
      if (this.formData.roles) {
        roleIds = this.formData.roles.map((role) => role.value);
      }
      if (this.formData.individualRecipients) {
        individualRecipientsIds = this.formData.individualRecipients.map(
          (individualRecipient) => individualRecipient.value
        );
      }
      const result = await createCPUpdate({
        title: this.formData.title,
        description: this.formData.description,
        acknowledgementDeadlineDate: this.formData.acknowledgementDeadlineDate,
        expiryDate: this.formData.expiryDate,
        lineStations: lineStations,
        roleIds: roleIds,
        attachmentIds: uploadedIds,
        individualRecipients: individualRecipientsIds,
      });
      if (result.success) {
        this.isSubmitting = false;
        this.$router.go(-1);
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
  },
};
</script>
